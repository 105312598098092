<template>
	<div class="bonus-box">
		<div class="bonus-top">
			<div class="bonus-top_content">
				<div class="bonus-top_left">
					<p class="title">{{ $t('Bonuses Pool') }}</p>
					<p class="subtitle">{{ $t('Combine NFT cards with huge prize') }}</p>
					<p class="total-amount">
						<img src="@/assets/BUSD.png" alt="">
						<span>{{ Number(total_amount).toFixed(4) }}</span>
					</p>
					<a :href="`https://bscscan.com/address/${factory_address}`" target="_blank">{{ $t('Smart Contract') }}</a>
				</div>
				<div class="bonus-top_right">
					<img src="@/assets/bonus_pool_top.png" alt="">
				</div>
			</div>

			<div class="table-box">
				<table>
					<tr>
						<th width="10%"></th>
						<th>{{ $t('NAME') }}</th>
						<th width="10%">{{ $t('SHARE') }}</th>
					</tr>
					<tr v-for="(item, index) in combine_list" :key="index">
						<td>{{ index + 1 }}</td>
						<td>{{ showZH ? item.name : item.name_en }}</td>
						<td>{{ item.point }}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import combine from '@/data/combine'
import { rewardAmt, bonusesList, factory_address } from '@/utils/cardFactory'
import { mapState } from 'vuex'

export default {
	name: 'BonusesPool',
	data () {
		return {
			factory_address,
			total_amount: 0,
			combine_list: combine,
			bonList: [],
		}
	},
	computed: {
		...mapState(['language']),
		showZH () {
			return this.language === 'zhHans' || this.language === 'zhHant' || this.language === 'ja'
		},
	},
	created () {
		this._rewardAmt()
	},
	methods: {
		async _rewardAmt () {
			this.total_amount = await rewardAmt()
		},
		async _history () {
			this.bonList = await bonusesList()
		},
	},
}
</script>

<style lang="scss" scoped>
// pc
@media (min-width: 960px) {
	.bonus-box {
		.title {
			font-size: 24px;
			line-height: 36px;
			margin-bottom: 6px;
		}

		.subtitle {
			font-size: 14px;
			line-height: 21px;
		}

		.bonus-top_left {
			a {
				font-size: 14px;
				line-height: 21px;
			}

			padding-top: 80px;
		}

		.table-box {
			padding: 140px 0;
		}

		.total-amount {
			img {
				margin-right: 24px;
				width: 65px;
			}
			font-size: 64px;
			line-height: 96px;
			margin: 40px 0;
		}
	}
}

// mobile
@media (max-width: 960px) {
	.bonus-box {
		.title {
			font-size: 18px;
			line-height: 27px;
			margin-bottom: 4px;
		}

		.subtitle {
			font-size: 12px;
			line-height: 18px;
		}

		.bonus-top_left {
			a {
				font-size: 12px;
				line-height: 18px;
			}

			padding-top: 15px;
		}

		.bonus-top_content {
			padding: 25px;
		}

		.table-box {
			padding: 46px 15px;
		}

		.total-amount {
			img {
				margin-right: 12px;
				width: 22px;
			}

			font-size: 20px;
			line-height: 30px;
			margin: 38px 0 4px;
		}
	}
}

.bonus-box {
	.table-box {
		table {
			td {
				&:first-child {
					color: #67717A;
				}

				padding-top: 30px;
				color: #ACB1B6;
			}

			th {
				text-align: left;
				padding-bottom: 10px;
				color: #67717A;
			}

			max-width: 450px;
			width: 100%;
			font-size: 14px;
		}

		background-color: #3C434A;
		display: flex;
		justify-content: center;
	}

	.bonus-top {
		.bonus-top_content {
			.bonus-top_right {
				img {
					width: 100%;
				}

				flex: 1;
			}

			display: flex;
			justify-content: space-between;
			max-width: 948px;
			margin: 0 auto;
		}

		.bonus-top_left {
			.title {
				font-weight: bold;
				color: #E8E8E8;
			}

			.subtitle {
				font-weight: 500;
				color: #67717A;
			}

			.total-amount {
				font-weight: bold;
				color: #EB6930;
				display: flex;
				align-items: center;
			}

			a {
				font-weight: 500;
				text-decoration-line: underline;
				color: #EB6930;
			}
		}

		background: #202831;
	}
}
</style>
